body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  min-height: calc(
    var(--vh, 1vh) * 100
  ); /* Use vh as a fallback for browsers that do not support Custom Properties */
  display: flex;
}

.page {
  width: 100%;
  overflow: auto;
}

.container {
  overflow: auto;
  position: relative;
  min-height: min-content;
}

.padding {
  padding: 20px;
}

.noPadding {
  padding: 0px;
}

.cardHeader {
  padding: 25px;
  position: absolute;
  margin-top: -50px;
  margin-left: -5px;
}

.card {
  padding: 20px;
  margin-top: 40px;
  position: relative;
}
